export const SCALE_VENDOR_IDS = [
    0x30_3A, // expressif
    0x10_C4, // CP210x uart bridge
];

export const SCANNER_VENDOR_IDS = [
    0x0C_2E, // Honeywell@Metrologic Instruments
];

export async function flushReadBuffer(reader: ReadableStreamDefaultReader<Uint8Array>): Promise<void> {
    let done = false;
    const flushTimeout = setTimeout(() => {
        done = true;
    }, 100);

    do {
        try {
            const timeout = setTimeout(() => reader.releaseLock(), 10);

            const response = await reader.read();
            console.log('scale', response.value);

            clearTimeout(timeout);

            if (response.done) {
                done = true;
            }
        }
        catch {}

        await new Promise(resolve => setTimeout(resolve, 100));
    } while (done);

    clearTimeout(flushTimeout);
}
