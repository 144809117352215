import {useEffect} from 'react';
import {useSelector} from 'react-redux';

import {SCANNER_VENDOR_IDS} from '@/utils/WebSerial';

const activePorts = new Map<SerialPort, {
    reader?: ReadableStreamDefaultReader<Uint8Array>,
}>();

let timeout: ReturnType<typeof setTimeout>;
async function openPort(port: SerialPort, onCode:((code: string) => void)) {
    if (
        !port
        || !SCANNER_VENDOR_IDS.includes(port.getInfo().usbVendorId)
    ) {
        return;
    }
    if (activePorts.get(port)) {
        return;
    }

    activePorts.set(port, {});

    await port.open({
        baudRate: 115_200,
    });

    const reader = port.readable.getReader();
    activePorts.set(port, {reader});

    let decodedBuffer = '';

    (async () => {
        const decoder = new TextDecoder();

        try {
            while (true) {
                const decoded = decoder.decode((await reader.read()).value);
                // console.log('scanner', decoded);

                if (timeout) {
                    decodedBuffer = decodedBuffer + decoded;
                }
                else {
                    decodedBuffer = decoded;
                }

                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    onCode(decodedBuffer);
                    decodedBuffer = '';
                    timeout = undefined;
                }, 50);
            }
        }
        catch (error) {
            console.log('maybe reconnect to serial scanner?', error);
        }
    })();
}

export async function requestSerialScannerPermission() {
    try {
        await window.navigator.serial.requestPort({filters: SCANNER_VENDOR_IDS.map(usbVendorId => ({usbVendorId}))});

        // port will be opened by <SerialScannerAutoConnect />
    }
    catch {}
}
window.requestSerialScannerPermission = requestSerialScannerPermission;

function SerialScannerAutoConnect({onCode}: {onCode: (code: string) => void}) {
    const refreshSerialPorts = useSelector(state => state.checkout.refreshSerial);

    useEffect(() => {
        (async () => {
            for (const port of await window.navigator.serial.getPorts()) {
                try {
                    await openPort(port, onCode);
                }
                catch (error) {
                    console.log(error);
                }
            }
        })();

        const onConnect = async (e: Event) => {
            try {
                await openPort(e.target as SerialPort, onCode);
            }
            catch (error) {
                console.log(error);
            }
        };
        const onDisconnect = async (e: Event) => {
            const port = e.target as SerialPort;
            const portDetails = activePorts.get(port);

            try {
                portDetails.reader.releaseLock();
                await portDetails.reader.cancel();
                await port.close();
            }
            catch {}

            activePorts.delete(port);
        };

        window.navigator.serial.addEventListener('connect', onConnect);
        window.navigator.serial.addEventListener('disconnect', onDisconnect);

        return () => {
            window.navigator.serial.removeEventListener('connect', onConnect);
            window.navigator.serial.removeEventListener('disconnect', onDisconnect);
        };
    }, [refreshSerialPorts, onCode]);

    return (
        null
    );
}

export default function GatedSerialScannerAutoConnect({onCode}: {onCode: (code: string) => void}) {
    return (
        window.navigator.serial
            ? <SerialScannerAutoConnect onCode={onCode} />
            : null
    );
}
