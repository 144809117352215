import {
    FloatingArrow,
    FloatingPortal,
    Placement,
    arrow,
    autoUpdate,
    flip,
    offset,
    shift,
    useDismiss,
    useFloating,
    useFocus,
    useHover,
    useInteractions,
    useMergeRefs,
    useRole,
    useTransitionStyles,
} from '@floating-ui/react';
import clsx from 'clsx';
import React, {Ref, useRef} from 'react';

import classes from './index.module.scss';

interface TooltipOptions {
    initialOpen?: boolean,
    placement?: Placement,
    open?: boolean,
    onOpenChange?: (open: boolean) => void,
}

export function useTooltip({
    initialOpen = false,
    placement = 'bottom',
    open: controlledOpen,
    onOpenChange: setControlledOpen,
}: TooltipOptions = {}) {
    const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen);
    const arrowRef = useRef<Element>();

    const open = controlledOpen ?? uncontrolledOpen;
    const setOpen = setControlledOpen ?? setUncontrolledOpen;

    const data = useFloating({
        placement,
        open,
        onOpenChange: setOpen,
        whileElementsMounted: autoUpdate,
        middleware: [
            offset(8),
            flip({
                crossAxis: placement.includes('-'),
                fallbackAxisSideDirection: 'start',
                padding: 5,
            }),
            shift({padding: 5}),
            arrow({
                element: arrowRef,
            }),
        ],
    });

    const context = data.context;

    const hover = useHover(context, {
        move: false,
        enabled: !controlledOpen,
    });
    const focus = useFocus(context, {
        enabled: !controlledOpen,
    });
    const dismiss = useDismiss(context);
    const role = useRole(context, {role: 'tooltip'});

    const interactions = useInteractions([hover, focus, dismiss, role]);

    return React.useMemo(
        () => ({
            open,
            setOpen,
            arrowRef,
            ...interactions,
            ...data,
        }),
        [open, setOpen, interactions, data],
    );
}

type ContextType = ReturnType<typeof useTooltip> | null;

const TooltipContext = React.createContext<ContextType>(null);

export const useTooltipContext = () => {
    const context = React.useContext(TooltipContext);

    if (!context) {
        throw new Error('Tooltip components must be wrapped in <Tooltip />');
    }

    return context;
};

export function Tooltip({
    children,
    ...options
}: {children: React.ReactNode} & TooltipOptions) {
    const tooltip = useTooltip(options);

    return (
        <TooltipContext.Provider value={tooltip}>
            {children}
        </TooltipContext.Provider>
    );
}

function TooltipTriggerRaw({children, ...props}: React.HTMLProps<HTMLElement>, propRef: Ref<HTMLElement>) {
    const context = useTooltipContext();
    const childrenRef = (children as any).ref;
    const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

    return (
        <div
            ref={ref}
            data-state={context.open ? 'open' : 'closed'}
            {...context.getReferenceProps(props)}
        >
            {children}
        </div>
    );
}
export const TooltipTrigger = React.forwardRef(TooltipTriggerRaw);

function TooltipContentRaw({style, size, ...props}: Omit<React.HTMLProps<HTMLDivElement>, 'size'> & {
    size?: 'small' | 'medium' | 'large',
}, propRef: React.Ref<HTMLDivElement>) {
    const context = useTooltipContext();
    const ref = useMergeRefs([context.refs.setFloating, propRef]);
    const {isMounted, styles} = useTransitionStyles(context, {
        duration: 200,
    });

    if (!isMounted) {
        return null;
    }

    const floatingProps = context.getFloatingProps(props);

    return (
        <FloatingPortal>
            <div
                ref={ref}
                style={{
                    ...context.floatingStyles,
                    ...styles,
                    ...style,
                }}
                {...floatingProps}
                className={clsx(classes['Tooltip'], {
                    [classes[`Tooltip--${size}`]]: size,
                }, floatingProps.className)}
            >
                {floatingProps.children}
                <FloatingArrow
                    ref={context.arrowRef}
                    context={context}
                    height={6}
                    tipRadius={2}
                    width={14}
                />
            </div>
        </FloatingPortal>
    );
}
export const TooltipContent = React.forwardRef(TooltipContentRaw);
